<!--
 * @Description:支付宝 微信 支付选择 choosePayStyle
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-10 10:23:17
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-nav-bar title="支付选择"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="content">
      <div class="content-text">
        <span>应付金额</span>
      </div>
      <div class="content-number">
        <span class="content-number-number">{{ needPay }}</span>
        <span>元</span>
      </div>
      <div class="paystyle-text">
        <span>支付方式</span>
      </div>
      <div class="weixin"
           :model="WX"
           @click="handleClickWeiChat">
        <div class="imgbox">
          <img class="paymentStyle-img"
               src="@/assets/parkingPayment/weixin.png"
               alt="" />
        </div>
        <span class="paymentStyle-text">微信支付</span>
        <span class="paymentStyle-check">
          <van-radio-group v-model="radio">
            <van-radio name="WX"></van-radio>
          </van-radio-group>
        </span>
      </div>
      <div class="zhifubao"
           :model="ZFB"
           @click="handleClickZhiFuBao">
        <div class="imgbox">
          <img class="paymentStyle-img"
               src="@/assets/parkingPayment/zhifubao.png"
               alt="" />
        </div>
        <span class="paymentStyle-text">支付宝支付</span>
        <span class="paymentStyle-check">
          <van-radio-group v-model="radio">
            <van-radio name="ZFB"></van-radio>
          </van-radio-group>
        </span>
      </div>
    </div>
    <van-button class="commitPay"
                @click="handleCommitPay()">确认支付</van-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      needPay: 0, // 应付金额
      radio: 'WX',
      WX: 0,
      ZFB: 0,
      prepayid: '', // 预支付交易会话ID
      nonceStr: '', // 随机串
      timeStamp: '', // 时间戳
      sign: ''// 签名
    }
  },
  created () {
    this.needPay = this.$route.query.payMoney
  },
  mounted () {
    const self = this
    window.payBack = function () {
      self.$router.go(-2)
    }
  },
  methods: {
    // 确认支付按钮
    handleCommitPay () {
      if (this.radio === 'WX') {
        // 调起微信支付
        const info = {
          orderSequence: this.$route.query.payOrder,
          amount: Number(this.needPay) * 100,
          description: '微信支付'
        }
        this.$choosePayStyle.wxAppPay(info).then(res => {
          this.prepayid = res.resultEntity.prepayid
          this.nonceStr = res.resultEntity.nonceStr
          this.timeStamp = res.resultEntity.timeStamp
          this.sign = res.resultEntity.sign
          if (window.android) {
            window.android.requestWxPay(res.resultEntity.prepayid, res.resultEntity.nonceStr, res.resultEntity.timeStamp, res.resultEntity.sign)
          } else if (window.webkit) {
            let str = {
              prepayid: res.resultEntity.prepayid,
              nonceStr: res.resultEntity.nonceStr,
              timeStamp: res.resultEntity.timeStamp,
              sign: res.resultEntity.sign,
              package: res.resultEntity.package
            }
            str = JSON.stringify(str)
            window.webkit.messageHandlers.requestWxPay.postMessage(str)
          }
        })
      } else if (this.radio === 'ZFB') {
        // 支付宝支付
        const info = {
          // amount: Math.floor(Math.random() * 10 + 1),
          orderSequence: this.$route.query.payOrder,
          amount: Number(this.needPay) * 100,
          description: '支付宝支付'
        }
        this.$choosePayStyle.zfbAliPay(info).then(res => {
          console.log('alires', res)
          if (window.android) {
            window.android.requestAliPay(res.resultEntity)
          } else if (window.webkit) {
            window.webkit.messageHandlers.requestAliPay.postMessage(res.resultEntity)
          }
        }).catch(err => {
          console.log('alierr', err)
        })
      } else {
        this.$dialog.alert({ message: '功能暂未开放!' })
      }
    },
    // 点击微信支付
    handleClickWeiChat () {
      this.radio = 'WX'
    },
    // 点击支付宝支付
    handleClickZhiFuBao () {
      this.radio = 'ZFB'
    },
    // 点击返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/*顶部*/
.top {
  width: 100%;
  height: 44px;
  background: #ffffff;
}
/*顶部返回图片*/
.top-back-img {
  width: 8.5px;
  height: 15px;
  margin-left: 2.5%;
  margin-top: 14.5px;
}
/*顶部返回文字*/
.top-back-text {
  width: 30.5px;
  height: 17.5px;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  margin-left: 2%;
}
/*顶部标题文字*/
.top-title-text {
  width: 30.5px;
  height: 17.5px;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  margin-left: 24%;
}
/*内容部分*/
.content {
  background: #ffffff;
  border-radius: 6px;
  width: 94%;
  margin-left: 3%;
  height: 275px;
  margin-top: 10px;
  padding-top: 35px;
}
/*应付金额文字*/
.content-text {
  /*background-color: red;*/
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.48px;
  width: 100%;
  height: 21px;
  line-height: 21px;
  text-align: center;
}
/*应付金额数字*/
.content-number {
  /*background-color: blue;*/
  width: 100%;
  height: 40px;
  margin-top: 5px;
  text-align: center;
}
.content-number-number {
  font-size: 28px;
  color: #333333;
  letter-spacing: 0;
  line-height: 40px;
}
.paystyle-text {
  width: 100%;
  height: 21px;
  line-height: 21px;
  margin-top: 42px;
  margin-left: 5.6%;
}
/*微信盒子*/
.weixin {
  /*background-color: blue;*/
  width: 100%;
  height: 56px;
  margin-top: 8px;
  display: flex;
}
.imgbox {
  /*background-color: green;*/
  width: 7%;
  height: 24px;
  margin-top: 15px;
  margin-left: 5.6%;
}
/*微信图标*/
.paymentStyle-img {
  width: 24px;
  height: 24px;
}

/*支付文字*/
.paymentStyle-text {
  /*background-color: red;*/
  width: 40%;
  height: 50px;
  line-height: 56px;
  margin-left: 1.9%;
  font-size: 15px;
  color: #666666;
  letter-spacing: 0;
}
/*支付选择*/
.paymentStyle-check {
  position: fixed;
  right: 10%;
  line-height: 56px;
  text-align: center;
  padding-top: 18px;
}
.zhifubao {
  /*background-color: red;*/
  width: 100%;
  height: 56px;
  margin-top: 8px;
  display: flex;
}
/*确认支付按钮*/
.commitPay {
  width: 94%;
  height: 48px;
  margin-left: 3%;
  margin-top: 5%;
  background-color: #4d95f4;
  font-size: 19px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 26px;
  border-radius: 6px;
}
.body {
  overflow-x: hidden;
}
</style>
